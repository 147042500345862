import React from 'react'
import b from "./wp3980927-mercedes-wallpapers.jpg"
import c from "./wp3980866-mercedes-wallpapers.jpg"
import a from "./wp12727447-sedan-cars-wallpapers.jpg"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { EffectFade } from 'swiper/modules';
import 'swiper/css/effect-fade';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import Aos from "aos"
import "aos/dist/aos.css"

   
const Hero = () => {
  useEffect(()=>{
    Aos.init({duration:900})
})
  return (
    <div className='relative'>

<div className='absolute h-full w-full flex flex-col justify-center items-center'>
     <div className='z-30 flex flex-col justify-center items-center'>
      <h1 data-aos="fade-up" className='md:text-[73px]  text-center md:px-8 px-8 no leading-normal font-black uppercase text-yellow-500 text-[29px] md:w-[850px]'>Arrive in luxury <span className='text-slate-50'>depart in style</span> with Hash limo</h1>
      <Link to="/reservation">    <button className=' rounded-[8px] capitalize w-[160px] px-4 md:py-2 py-1 text-yellow-500 hover:bg-yellow-700 md:text-[23px] text-[15px] hover:text-white transition ease-in duration-100 no border-2 border-yellow-600 bg-black'>Reservation</button></Link>
     </div>
     </div>

    <Swiper
    spaceBetween={30}
    centeredSlides={true}
    autoplay={{
        delay:1500,
        disableOnInteraction: false,
    }}
    pagination={{
      clickable: true,
      el: '.swiper-pagination', // Add class name for the pagination container
    }}
    navigation={{
      nextEl: '.swiper-button-next', // Add class name for the next button
      prevEl: '.swiper-button-prev', // Add class name for the previous button
    }}
    modules={[Autoplay, Pagination, Navigation, EffectFade]}
    effect='fade'
    loop={true} // Add loop property to create a continuous loop of slides
    speed={800} // Adjust the speed (in milliseconds) to control the slow-motion effect
    className="mySwiper"
>
    
  
 
  

    
  


<SwiperSlide>
 <img loading='lazy' class="w-[100%] h-[430px] md:w-[100%] md:h-screen object-cover object-center brightness-50" src={b} /> 
</SwiperSlide>
<SwiperSlide>
  <img loading='lazy' class="w-[100%] h-[430px] md:w-[100%] md:h-screen object-cover object-center brightness-50" src={c} />

</SwiperSlide>
<SwiperSlide>
  <img loading='lazy' class="w-[100%] h-[430px] md:w-[100%] md:h-screen object-cover object-center brightness-50" src={a} />

</SwiperSlide>




  </Swiper>



  
  </div>

  )
}

export default Hero