import React from 'react'
import { useEffect } from 'react';
import Aos from "aos"
import "aos/dist/aos.css"

const Contact = () => {
    useEffect(()=>{
        Aos.init({duration:100})
    })
  return (
    <>
   <div>
      <iframe
        title="Limo Anywhere Login"
        src="https://book.mylimobiz.com/v4/hashlimo/widget/login"
        width="100%"
        height="600"
       
      ></iframe>
    </div>
  </>
);
};



export default Contact