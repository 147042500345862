import React, { useEffect } from 'react';
import { useState } from 'react';
import Aos from "aos";
import "aos/dist/aos.css";

const Resform = () => {

  useEffect(() => {
    Aos.init({ duration: 200 });
  });

  return (
    <>
      <div className="h-[150px] h-[200px] bg-gray-700 flex justify-center items-center">
        <h className="md:text-[30px] text-[19px] text-white no">Home / <span className="text-yellow-500">Reservation</span></h>
      </div>
      <div data-aos="fade-up" className="md:mx-10 mb-5 mx-2 mt-10">
        <iframe className='w-full h-[1200px]'
          title="hello"
          src="https://book.mylimobiz.com/v4/hashlimo"
         
        ></iframe>
      </div>
    </>
  );
};

export default Resform;
