import React from 'react'

const Header = () => {
  return (
    <div className='md:h-[70px] h-[1px] w-full md:flex hidden md:px-10 md:flex-row md:justify-between bg-gray-700 items-center'>

        <div className='flex flex-row justify-center items-center gap-x-3'>
        <svg className='text-yellow-500' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 12 12"><path fill="currentColor" d="m5.966 4.49l-.827.742a4.914 4.914 0 0 0 .455 1.073a4.736 4.736 0 0 0 .722.922l1.071-.33c.6-.185 1.255.005 1.654.48l.61.726a1.47 1.47 0 0 1-.137 2.042c-.995.908-2.527 1.215-3.674.314a10.429 10.429 0 0 1-2.516-2.87A9.986 9.986 0 0 1 2.03 4.013c-.22-1.422.821-2.56 2.119-2.948c.774-.232 1.6.166 1.884.908l.335.875c.22.576.062 1.225-.402 1.641Z"/></svg>
        <p className='no text-gray-200 font-semibold'>888-383-3124</p>
        </div>
        

        <div className='flex flex-row justify-center items-center gap-x-3'>
        <svg className='text-yellow-500' xmlns="http://www.w3.org/2000/svg" width="26" height="28" viewBox="0 0 24 24"><path fill="currentColor" d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5l-8-5V6l8 5l8-5v2z"/></svg>
        <p className='no text-gray-200 font-semibold'>Hashlimollc@gmail.com</p>
        </div>

       

    </div>
  )
}

export default Header