import React, { useEffect } from 'react'
import Aos from "aos"
import "aos/dist/aos.css"
import { Link } from 'react-router-dom'
import { useState } from 'react'

const Blocks = () => {
    const recipientEmail = 'Hashlimollc@gmail.com';
    const [senderName, setSenderName] = useState('');
    const [message, setMessage] = useState('');
  
    const subject = `${senderName}: ${message}`;
  
    const handleContactClick = () => {
      const mailtoUrl = `mailto:${recipientEmail}?subject=${encodeURIComponent(subject)}`;
      window.location.href = mailtoUrl;
    };
    useEffect(()=>{
        Aos.init({duration:100})
    })
  return (
    <div className='bg-bhutdark py-8 w-full md:h-[400px] mt-6 md:px-40 px-3 gap-x-3 gap-y-5 flex md:flex-row flex-col justify-between items-center'>
        <div data-aos="fade-up" className='flex flex-col justify-center items-center gap-y-2'>
            <h1 style={{ fontFamily: 'Alisandra-Bold' }} className='text-yellow-400  md:text-[37px] text-[18px] capitalize tracking-wide'>Contact Us</h1>
            <p className='md:w-[280px] text-center md:px-none px-4 text-gray-100 px-2 ro md:text-[27px] text-[15px] tracking-tight'>Keep in touch with us to remain updated.</p>
            <button onClick={handleContactClick} className='capitalize w-[150px] rounded-[8px] px-4 md:py-2 py-1 text-yellow-500 hover:bg-yellow-700 md:text-[23px] text-[15px] hover:text-white transition ease-in duration-100 no border-2 border-yellow-600 bg-black'>Contact</button>
        </div>

        <div  data-aos="flip-left" className='flex flex-col justify-center items-center gap-y-2'>
            <h1 style={{ fontFamily: 'Alisandra-Bold' }} className='text-yellow-400  md:text-[37px] text-[18px] capitalize tracking-wide'>request a quote</h1>
            <p className='md:w-[270px] text-center md:px-none px-4 text-gray-100 px-2 ro md:text-[27px] text-[15px] tracking-tight'>Need a quote? Request it in two easy steps.</p>
        <Link to="/reservation">    <button className=' rounded-[8px] capitalize w-[150px] px-4 md:py-2 py-1 text-yellow-500 hover:bg-yellow-700 md:text-[23px] text-[15px] hover:text-white transition ease-in duration-100 no border-2 border-yellow-600 bg-black'>request</button></Link>


        </div>

        <div  data-aos="fade-up" className='flex flex-col justify-center items-center gap-y-2'>
            <h1 style={{ fontFamily: 'Alisandra-Bold' }} className='text-yellow-400  md:text-[37px] text-[18px] capitalize tracking-wide'>want an account</h1>
            <p className='md:w-[270px] md:px-none px-4 text-center text-gray-100 px-2 ro md:text-[27px] text-[15px] tracking-tight'>Manage your account Anywhere, Anytime.</p>
           <Link to="/account"> <button className='rounded-[8px] capitalize w-[150px] px-4 md:py-2 py-1 text-yellow-500 hover:bg-yellow-700 md:text-[23px] text-[15px] hover:text-white transition ease-in duration-100 no border-2 border-yellow-600 bg-black'>create</button></Link>

        </div>
        </div>
  )
}

export default Blocks