import React, {  useState } from 'react';
import qa from "./asaaaa-removebg-preview.png"
import { useEffect } from 'react';
import Aos from "aos"
import "aos/dist/aos.css"
import { HashLink as Link } from 'react-router-hash-link';




const Navbar = () => {
  const [openNavbar, setOpenNavbar] = useState(false);
  const [activeLink, setActiveLink] = useState('home'); // Initialize with the default active link

  const toggleNavbar = () => {
    setOpenNavbar((prevOpenNavbar) => !prevOpenNavbar);
  };

  const handleLinkClick = (link) => {
    setActiveLink(link);
    setOpenNavbar(false); // Close the navbar when a link is clicked
  };
  useEffect(()=>{
    Aos.init()
  })
  // if (isLoading) {
  //   return <div className='bg-gray-400 h-screen w-full flex justify-center items-center'>
  //       <img className='h-[50px]' src={spin} />
  //   </div>;
  // }
  return (
    <div className='mx-30'>
     <div id="home" className='bg-gray-100 w-[100%] flex flex-row justify-between mx-30 items-center md:px-12 px-4 py-2'>
        <div className=''>
          <img className=' cursor-pointer md:w-[300px] md:h-[100px] h-[60px] text-white bg-transparent leading-normal border-none w-[140px] object-fit m-0' src={qa} />
        </div>

        <div className='md:flex gap-x-4 capitalize hidden bounce-in-top'>
  <Link to="/" onClick={() => handleLinkClick('home')} className={`hover:text-txtdark cursor-pointer text-black ${activeLink === 'home' ? 'active-link' : ''} text-[27px]`}>
    Home
  </Link>
  <Link to="/about" onClick={() => handleLinkClick('blog')} className={`hover:text-txtdark cursor-pointer ${activeLink === 'blog' ? 'active-link' : ''}  text-black  text-[27px]`}>
    Blog
  </Link>
  <Link to="/airport" onClick={() => handleLinkClick('airport')} className={`hover:text-txtdark cursor-pointer ${activeLink === 'airport' ? 'active-link' : ''} text-black text-[27px]`}>
    Airport
  </Link>
  <Link to="/fleet" onClick={() => handleLinkClick('fleet')} className={`hover:text-txtdark cursor-pointer ${activeLink === 'fleet' ? 'active-link' : ''} text-black text-[27px]`}>
    Fleet
  </Link>
  <Link to="/reservation" onClick={() => handleLinkClick('res')} className={`hover:text-txtdark cursor-pointer ${activeLink === 'res' ? 'active-link' : ''} text-black text-[27px]`}>
    Reservation
  </Link>
</div>



        <div onClick={toggleNavbar} className='hover:shadow-lg p-2 rounded-[8px] hover:text-yellow-700 text-black font-[25px] mr-3 ease-in duration-100 md:hidden flex md:text-[16px] text-[16px] scale-up-center cursor-pointer'>
          {openNavbar ? <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M6.758 17.243L12.001 12m5.243-5.243L12 12m0 0L6.758 6.757M12.001 12l5.243 5.243"/></svg> : <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 20 20"><path fill="currentColor" fill-rule="evenodd" d="M2 4.75A.75.75 0 0 1 2.75 4h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 4.75Zm7 10.5a.75.75 0 0 1 .75-.75h7.5a.75.75 0 0 1 0 1.5h-7.5a.75.75 0 0 1-.75-.75ZM2 10a.75.75 0 0 1 .75-.75h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 10Z" clip-rule="evenodd"/></svg>}
        </div>
      </div>

      {/* The conditional rendering is done correctly */}
      <div className={openNavbar ? 'fixed top-0 bottom-0 left-0  hover:bg-yellow-500 hover:text-white transition linear duration-500 right-0 bg-gray-100 z-50 text-white border-gray-700 slide-in-left  flex flex-col justify-center shadow-lg z-50 slide-in-left w-[280px] px-8 py-8' : 'hidden'}>
    
<img className='w-[180px] h-[120px] absolute top-0 left-4' src={qa} />
 <svg className='absolute top-5 right-5 text-red-400' onClick={(()=>{
      setOpenNavbar(false)
    })} xmlns="http://www.w3.org/2000/svg" width="28" height="40" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M6.758 17.243L12.001 12m5.243-5.243L12 12m0 0L6.758 6.757M12.001 12l5.243 5.243"/></svg> 
           <div className=' flex flex-col gap-y-6 justify-around align-center text-black'>

          <div className='flex flex-row items-center gap-x-2'>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="M5 12H3l9-9l9 9h-2M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-7"/><path d="M9 21v-6a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v6"/></g></svg>
       <Link to="/" smooth> <a onClick={(()=>{
        setOpenNavbar(false)
       })} className='cursor-pointer text-black ro uppercase font-extrabold  border-yellow-500 px-2 py-1'>Home</a></Link>
       
       </div>

       <div className='flex flex-row items-center gap-x-2'>
       <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20"><path fill="currentColor" d="m16.89 1.2l1.41 1.41c.39.39.39 1.02 0 1.41L14 8.33V18H3V3h10.67l1.8-1.8c.4-.39 1.03-.4 1.42 0zm-5.66 8.48l5.37-5.36l-1.42-1.42l-5.36 5.37l-.71 2.12z"/></svg>
       <Link to="/about" smooth> <a onClick={(()=>{
        setOpenNavbar(false)
       })}  className='cursor-pointer text-black ro uppercase font-extrabold border-yellow-500 px-2  py-1'>Blog</a></Link>
</div>
<div className='flex flex-row items-center gap-x-2' >
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M10 5c-4.42 0-8 .5-8 4v10c0 .85.37 1.66 1 2.22V23c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1h8v1c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1.78c.63-.56 1-1.37 1-2.22V9c0-3.5-3.58-4-8-4M5.5 20c-.83 0-1.5-.67-1.5-1.5S4.67 17 5.5 17s1.5.67 1.5 1.5S6.33 20 5.5 20m9 0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5s1.5.67 1.5 1.5s-.67 1.5-1.5 1.5m1.5-6H4V9h12v5m6-9v10c0 .85-.37 1.66-1 2.22V19c0 .55-.45 1-1 1h-.12c.07-.32.12-.65.12-1V9c0-6-7-6-10-6c-.91 0-2.2 0-3.54.17C7.55 1.32 10.5 1 14 1c4.42 0 8 .5 8 4Z"/></svg>
       <Link to="/fleet" smooth> <a onClick={(()=>{
        setOpenNavbar(false)
       })}  className='cursor-pointer text-black ro uppercase font-extrabold border-yellow-500 px-2  py-1 '>Fleet</a></Link>
</div>
<div className='flex flex-row items-center gap-x-2'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 2048 2048"><path fill="currentColor" d="M896 512v128H512V512h384zM512 896V768h384v128H512zm0 256v-128h256v128H512zM384 512v128H256V512h128zm0 256v128H256V768h128zm-128 384v-128h128v128H256zM128 128v1792h640v128H0V0h1115l549 549v219h-128V640h-512V128H128zm1024 91v293h293l-293-293zm640 805h256v1024H896V1024h256V896h128v128h384V896h128v128zm128 896v-512h-896v512h896zm0-640v-128h-896v128h896z"/></svg>
       <Link to="/reservation" smooth> <a onClick={(()=>{
        setOpenNavbar(false)
       })}  className='cursor-pointer text-black ro uppercase font-extrabold  border-yellow-500 px-2  py-1'>Reservation</a></Link>
       </div>
<div className='flex flex-row items-center gap-x-2'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32"><path fill="currentColor" d="M16 3c-1.645 0-3 1.355-3 3v7.344l-8.406 3.75l-.594.25v4.78L5.125 22L13 21.125v1.844l-2.563 1.717l-.437.28v4.253l1.188-.25L16 28l4.813.97l1.187.25v-4.25l-.438-.282L19 22.968v-1.843l7.875.875l1.125.125v-4.78l-.594-.25L19 13.344V6c0-1.645-1.355-3-3-3zm0 2c.565 0 1 .435 1 1v8.656l.594.25L26 18.656v1.22L18.125 19L17 18.875v5.187l.438.282L20 26.062v.72l-3.813-.75L16 25.97l-.188.06l-3.812.75v-.72l2.563-1.717l.437-.282v-5.186L13.875 19L6 19.875v-1.22l8.406-3.75l.594-.25V6c0-.565.435-1 1-1z"/></svg>
<Link to="/airport" smooth> <a onClick={(()=>{
        setOpenNavbar(false)
       })}  className='cursor-pointer text-black ro uppercase font-extrabold border-yellow-500 px-2  py-1'>Airport</a></Link>
      
   </div>



       
       
       
        </div>
      </div>

      
  
    </div>
  );
};

export default Navbar;
