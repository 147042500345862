import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import Hero from './Hero';
import { Tooltip } from '@mui/material';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Donation from './Donation';
import Cards from './Cards';
import Cardstwo from './Cardstwo';
import Video from './Video';
import Reviews from './Reviews';
import Gallery from "./Gallery";
import Footer from './Footer';
import Header from './Header';
import Blocks from './Blocks';
import Home from './pages/Home';
import About from './pages/About';
import Resform from './pages/Resform';
import Fleet from './Fleet';
import Airport from './pages/Airport';
import Scroll from "./Scroll";
import Contact from './Contact';
import Account from './pages/Account';

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a 5-second delay before hiding the loader
    setTimeout(() => {
      setLoading(false);
    }, 5000); // 5000 milliseconds (5 seconds)
  }, []); // Empty dependency array to ensure this effect only runs once

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // You can change this to 'auto' for an instant scroll
    });
  };

  return (
    <>
     
     
        <Router>
          <Header />
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/reservation" element={<Resform />} />
            <Route path="/account" element={<Account />} />
            <Route path="/fleet" element={<Fleet />} />
            <Route path="/airport" element={<Airport />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
          <Footer />
          {/* Add scroll-to-top button */}
          <Tooltip title="Back Top">
            <svg
              className="scroll-to-top-button text-[50px] p-2 rounded-full bg-white text-yellow-500"
              onClick={scrollToTop}
              xmlns="http://www.w3.org/2000/svg"
              width="44"
              height="44"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M3 19h18a1.002 1.002 0 0 0 .823-1.569l-9-13c-.373-.539-1.271-.539-1.645 0l-9 13A.999.999 0 0 0 3 19z"
              />
            </svg>
          </Tooltip>
        </Router>
      
    </>
  );
};

export default App;
