import React from 'react'
import Aos from "aos"
import { useEffect } from 'react'
import "aos/dist/aos.css"
import { Link } from 'react-router-dom'
import c from "./hemlo.jpg"
import a from "./wp3980866-mercedes-wallpapers.jpg"



const Donation = () => {
  useEffect(()=>{
    Aos.init({duration:900})
  })
  return (
    <div className='py-10'>
    <div id="vision" className='flex md:flex-row flex-col gap-x-5 xl:justify-between lolo md:py-12 py-6 gap-y-6 md:px-14  justify-between items-center'>

      

  


<div className='relative' style={{
    backgroundImage: `url(${a})`,
    backgroundSize: 'cover', // You can adjust this property as needed
    backgroundPosition: 'center center', // You can adjust this property as needed
  }}>
    <div className='card-overlay p-2s'>
      <div className='flex brightness-100 flex-col jutify-center  items-center card-overlay gap-y-4 max-w-[860px] roudned-[5px] py-6 md:px-none px-3'>
      <h1 style={{ fontFamily: 'Alisandra-Bold' }}  data-aos="fade-down" className='px-4 md:text-[70px] text-[28px] text-center capitalize font-semibold tracking-wide'>
  <span className="text-yellow-400 relative font-bold">
Welcome to Hash<span className='text-yellow-500'> limo</span>
    <span className="absolute inset-x-0 bottom-0 h-1 bg-gray-100"></span>
  </span>
</h1>

        <p data-aos="fade-down" className='text-center px-4 break-normal leading-loose tracking-wide mo text-white md:font-normal md:text-[30px] text-[15px] capitalize  max-w-[810px]'>We not only service the New York Tri-State Area, but we are a global car service that can take care of all your transportation needs, anywhere in the world. Check out all of our services and get your custom quote today. We honor and match fully licensed and insured competitor’s coupons.</p>
       <Link to="/about"><button  className='capitalize md:w-[230px] w-[160px] md:py-2 py-1 text-yellow-400 font-bold hover:bg-yellow-700 md:text-[23px] text-[15px] hover:text-white transition ease-in duration-200 ro border-l-2 tappu border-r-2  border-r-yellow-500 border-double border-l-yellow-500 bg-transparent'>read more</button></Link> 
       </div>
 
</div>
      </div>

      <img loading='lazy' class="w-[100%] h-[330px] md:w-[44%] md:h-[700px] object-cover rounded-[5px] object-center" src={c} />

     

    </div>

    
    </div>
  )
}

export default Donation