import React, { useEffect } from 'react'
import a from "./opo.jpg"
import b from "./pexels-matheus-bertelli-3856033.jpg"
import c from "./point.jpg"
import d from "./cooo.jpg"
import Aos from "aos"
import "aos/dist/aos.css"
import { useState } from 'react'


const Cards = () => {

  useEffect(()=>{
    Aos.init({duration:900})
  })
  return (


    <>
    <h1 style={{ fontFamily: 'Alisandra-Bold' }} className='md:text-[65px]   text-[23px] mb-2 tracking-wide font-bold md:font-extrabold text-center text-gray-100'><span className='text-yellow-500'>Services</span> We Offer</h1>
    <div className="flex md:flex-row flex-col gap-x-1 gap-y-3 md:py-7 py-2 justify-between items-center md:px-14 px-6">


<div data-aos="fade-up" class="md:max-w-md w-full bg-gray-800 rounded overflow-hidden shadow-lg bappu ">
    <img loading='lazy' class="w-full h-[200px] md:w-[100%] md:h-[340px] object-cover  scale-on-hover cursor-pointer object-center" src={a} />
    <div class="md:px-6 px-4 md:py-4 py-2">
        <div class="font-bold md:text-[32px] text-[20px] mb-2 text-yellow-500 no">Airport Transfer</div>
        <p class="text-slate-50 md:text-[24px] text-[13px] md:mb-2 mb-1 jo">
        Our airport transfer service ensures reliable and convenient rides to and from the airport, providing a stress-free start or end to your journey
        </p>
    </div>
</div>





<div data-aos="fade-down " class="max-w-md bg-gray-800 rounded overflow-hidden shadow-lg">
<img loading='lazy' class="w-full h-[200px] md:w-[100%] md:h-[340px] object-cover object-center scale-on-hover cursor-pointer" src={d} />
  <div class="md:px-6 px-4 md:py-4 py-2">
    <div class="font-black md:text-[32px] text-[21px] mb-2 text-yellow-500 no">Corporate Travel</div>
    <p class="text-slate-50 md:text-[24px] text-[13px] md:mb-2 mb-1 jo">
    Catering to corporate travel needs, we offer efficient and tailored solutions. Our services prioritize punctuality, comfort, and seamless business travel experiences.
    </p>
  </div>
 
</div>




<div data-aos="fade-up" class="max-w-md bg-gray-800 rounded overflow-hidden shadow-lg">
<img loading='lazy' class="w-full h-[200px] md:w-[100%] md:h-[340px] object-cover object-center scale-on-hover cursor-pointer" src="https://images.pexels.com/photos/59884/pexels-photo-59884.jpeg?auto=compress&cs=tinysrgb&w=600" />
  <div class="md:px-6 px-4 md:py-4 py-2">
    <div class="font-bold md:text-[32px] text-[21px] mb-2 text-yellow-500 no">Wedding And Anniversaries</div>
    <p class="text-slate-50 md:text-[24px] text-[13px] md:mb-2 mb-1 jo">
    Create unforgettable memories with our wedding and anniversary services. We provide elegant transportation for your special day, ensuring timeless moments.
    </p>
  </div>
 
</div>

    </div>



    <div className="flex md:flex-row flex-col gap-x-1 gap-y-3 py-3 md:justify-center gap-x-20 items-center mb-5 md:px-14 px-6">


<div data-aos="fade-up" class="max-w-md bg-gray-800 rounded overflow-hidden shadow-lg bappu ">
    <img loading='lazy' class="w-full h-[200px] md:w-[100%] md:h-[340px] object-cover  scale-on-hover cursor-pointer object-center" src="https://images.pexels.com/photos/7944238/pexels-photo-7944238.jpeg?auto=compress&cs=tinysrgb&w=600" />
    <div class="md:px-6 px-4 md:py-4 py-2">
        <div class="font-bold md:text-[30px] text-[21px] mb-2 text-yellow-500 no">Proms And Graduations</div>
        <p class="text-slate-50 md:text-[24px] text-[13px] md:mb-2 mb-1 jo">
        Make your prom or graduation night truly memorable with our dedicated transportation service. Arrive in style and safety to celebrate your achievements
        </p>
    </div>
</div>





{/* <div data-aos="fade-down " class="max-w-md bg-gray-800 rounded overflow-hidden shadow-lg">
<img class="w-full h-[200px] md:w-[100%] md:h-[340px] object-cover object-center scale-on-hover cursor-pointer" src="https://images.pexels.com/photos/5835268/pexels-photo-5835268.jpeg?auto=compress&cs=tinysrgb&w=600" />
  <div class="md:px-6 px-4 md:py-4 py-2">
    <div class="font-black md:text-[32px] text-[21px] mb-2 text-yellow-500 no">Corporate Travel</div>
    <p class="text-slate-50 md:text-[24px] text-[13px] md:mb-2 mb-1 jo">
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.
    </p>
  </div>
 
</div> */}




<div data-aos="fade-up" class="max-w-md bg-gray-800 rounded overflow-hidden shadow-lg">
<img loading='lazy' class="w-full h-[200px]  md:w-[100%] md:h-[340px] object-cover object-center scale-on-hover cursor-pointer" src={c} />
  <div class="md:px-6 px-4 md:py-4 py-2">
    <div class="font-bold md:text-[32px] text-[21px] mb-2 text-yellow-500 no">Point to Point</div>
    <p class="text-slate-50 md:text-[24px] text-[13px] md:mb-2 mb-1 jo">

    Experience hassle-free point-to-point rides with us. Our reliable service ensures you reach your destination swiftly and comfortably, without any detours.    </p>
  </div>
 
</div>

    </div>
    </>
  
  )
}

export default Cards