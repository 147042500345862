import React, { useState, useEffect } from 'react';
import Hero from '../Hero';
import Blocks from '../Blocks';
import Donation from '../Donation';
import Gallery from "../Gallery";
import Resform from './Resform';
import Cards from '../Cards';
import Scroll from '../Scroll';
import Contact from '../Contact';

const Home = () => {
  const [loading, setLoading] = useState(true);

// Empty dependency array to ensure this effect only runs once

  return (
    <div>
     
        <>
          <Hero />
          <Blocks />
          <Donation />
          {/* <Resform /> */}
          <Cards />
          {/* <Cards /> */}
        </>
      
    </div>
  );
}

export default Home;
