import React from 'react'
import qa from "./asaaaa-removebg-preview.png"
import Aos from "aos"
import "aos/dist/aos.css"
import { useEffect } from 'react'
import { HashLink as Link } from 'react-router-hash-link'

const Footer = () => {
    useEffect(()=>{
        Aos.init({duration:900})
      })
  return (
    <div className=''>
    <div className="relative ">
 
  <div className="bg-bhutdark flex flex-col md:h-[480px] h-[780px] justify-center md:px-14 py-2 px-4 md:gap-y-4 gap-y-5 text-white">
    <div className='flex md:flex-row flex-col justify-between'>
      <div data-aos="fade-in" className='flex flex-col justify-center gap-y-4'>
        <img className='md:w-[330px] w-full h-[200px] px-8' src={qa} />
        <p className='text-white text-center md:text-left md:text-[22px] md:w-[370px] text-[15px] tracking-tight no px-4'>Hashlimo offers luxury ground transportation throughout CT, MA, NY, NJ, IL. We offer sedans, SUVs, sprinter vans, party buses, and motor coaches up to 56 passengers capacity</p>
        <p className='text-yellow-600 md:text-[23px] md:text-left md:px-3 text-center text-[17px] no font-semibold'>Hashlimollc@gmail.com</p>
      </div>


<div className='flex  flex-col justify-center items-center gap-y-4 mt-1'>
<p data-aos="fade-down" className='text-yellow-600 ro font-extrabold md:text-[30px] text-[11px] tracking-wider tracking-tight flex justify-center  items-center'>Copyright Hash Limo LLC. ALL RIGHTS RESERVED</p>

</div>



<div className='flex flex-col justify-center items-center gap-y-1 mt-3'>
<div className='flex md:flex-row flex-col justify-center items-center gap-x-1'>
    <Link to="https://www.facebook.com/profile.php?id=61550086934000&mibextid=eBUYbo"> <svg className='text-yellow-500' xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24"><path fill="currentColor" d="M9.198 21.5h4v-8.01h3.604l.396-3.98h-4V7.5a1 1 0 0 1 1-1h3v-4h-3a5 5 0 0 0-5 5v2.01h-2l-.396 3.98h2.396v8.01Z"/></svg></Link>
     <h1 className='font-bold md:text-[30px] text-[18px] ro'>Facebook</h1>
     </div>

     <div className='flex md:flex-row flex-col justify-center items-center gap-x-1'>
     <svg className='text-yellow-500' xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24"><path fill="currentColor" d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5l-8-5V6l8 5l8-5v2z"/></svg>     
     <h1 className='font-bold md:text-[30px] text-[18px] ro'>Hashlimollc@gmail.com</h1>
     </div>


     <div className='flex md:flex-row flex-col justify-center items-center gap-x-1'>
     <svg className='text-yellow-500' xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24"><g fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="M0 0h24v24H0z"/><path fill="currentColor" d="M9 3a1 1 0 0 1 .877.519l.051.11l2 5a1 1 0 0 1-.313 1.16l-.1.068l-1.674 1.004l.063.103a10 10 0 0 0 3.132 3.132l.102.062l1.005-1.672a1 1 0 0 1 1.113-.453l.115.039l5 2a1 1 0 0 1 .622.807L21 15v4c0 1.657-1.343 3-3.06 2.998C9.361 21.477 2.522 14.638 2 6a3 3 0 0 1 2.824-2.995L5 3h4z"/></g></svg>     <h1 className='font-bold md:text-[30px] text-[18px] ro'>888-383-3124</h1>
     </div>


     </div>


     

     </div>

   
  </div>
</div>








   </div>
  )
}

export default Footer