import React from 'react'
import one from "./mlk-removebg-preview.png"
import two from "./two-removebg-preview.png"
import three from "./three-removebg-preview.png"
import four from "./lkl-removebg-preview.png"
import five from "./merci-removebg-preview.png"
import six from "./six-removebg-preview.png"
import seven from "./seven-removebg-preview.png"
import eght from "./gmc-removebg-preview.png"
import nne from "./nne-removebg-preview.png"
import cad from "./cad-removebg-preview.png"
import { useEffect } from 'react';
import { useState } from 'react'

import Aos from "aos"
import "aos/dist/aos.css"
const Fleet = () => {
    useEffect(()=>{
        Aos.init({duration:900})
    })
   
  return (
    <>
    <div className='realative flex justify-center items-center bg-gray-800 h-[400px] top-0'>
<div className='flex flex-col justify-center items-center gap-y-2'>

<p style={{ fontFamily: 'Alisandra-Bold' }} className='text-white md:text-[32px] text-[17px] tracking-wide md:px-1 px-5 text-center '>Our Fleets - Luxury Sedan, SUV's And Sprinters</p>
           
        <h1 data-aos="fade-down" className='no text-yellow-500 md:text-[30px] text-[20px]'>Home /<span className='text-white'>Fleet</span></h1>

        </div>


    </div>
    <div data-aos="fade-down" className='flex md:flex-row flex-col bg-black md:justify-between md:px-12 px-4 mt-6 mb-6 justify-center items-center'>
            <div className='flex flex-col justify-center items-center py-3 gap-y-4'>
                <h className="text-white font-semibold no md:text-[28px] text-[19px]">LINCOLN NAUTILUS</h>
                <div className='bg-black'>
                <img className='bg-none px-6 object-cover object-center md:w-[600px] h-[300px] w-full' src={one} /></div>
            </div>
            <div className='flex flex-col justify-center items-center  py-3 gap-y-4'>
                <h className="text-white font-semibold no md:text-[28px] text-[19px]">MERCEDES S550</h>
                <img className='bg-none px-6 object-cover object-center md:w-[600px] w-full' src={two} />
            </div>
            <div className='flex flex-col justify-center items-center  py-3 gap-y-4'>
                <h className="text-white font-semibold no md:text-[28px] text-[19px]">LINCOLN CONTINENTAL</h>
                <img className='bg-none px-6 object-cover object-center md:w-[600px] w-full' src={three} />
            </div>
        </div>


        <hr className='border-2 border-yellow-500 h-[3px] mr-3 ml-3' />




        <div data-aos="fade-up" className='flex md:flex-row flex-col bg-black md:justify-between gap-y-5 md:px-12 px-4 mt-6 mb-6 justify-center items-center'>
            <div className='flex flex-col justify-center items-center  py-3 gap-y-4'>
                <h className="text-white font-semibold no md:text-[28px] text-[19px]">BMW 7 SERIES</h>
                <div className='bg-black'>
                <img className='bg-none px-6 object-cover object-center md:w-[600px] w-full h-[300px]' src={four} /></div>
            </div>
            <div className='flex flex-col justify-center items-center  py-3 gap-y-4'>
                <h className="text-white font-semibold no md:text-[28px] text-[19px]">MERCEDES SPRINTER</h>
                <img className='bg-none px-6 object-cover object-center md:w-[400px] w-full' src={five} />
            </div>
            <div className='flex flex-col justify-center items-center  py-3 gap-y-4'>
                <h className="text-white font-semibold no md:text-[28px] text-[19px]">CHEVROLET SUBURBAN</h>
                <img className='bg-none px-6 object-cover object-center md:w-[600px] w-full' src={six} />
            </div>
        </div>


        <hr className='border-2 border-yellow-500 h-[3px] mr-3 ml-3' />



        <div data-aos="fade-down" className='flex md:flex-row flex-col md:justify-between bg-black md:px-12 px-4 mt-6 mb-6 gap-y-7 justify-center items-center'>
            <div className='flex flex-col justify-center items-center  py-3 gap-y-4'>
                <h className="text-white font-semibold no md:text-[28px] text-[19px]">CADILLAC ESCALADE</h>
                <div className='bg-black'>
                <img className='bg-none px-6 object-cover object-center md:w-[600px] w-full'  src={seven} /></div>
            </div>
            <div className='flex flex-col justify-start items-center relative py-3 gap-y-4'>
                <h className="text-white font-semibold no md:text-[28px] text-[19px] absolute -top-12">GMC YUKON DENALI</h>
                <img className='bg-none px-6 object-cover object-center md:w-[600px] w-full' src={eght} />
            </div>
            <div className='flex flex-col justify-center items-center  py-3 relative gap-y-4 mt-4 md:mt-1'>
                <h className="text-white font-semibold no md:text-[28px] text-[19px] absolute -top-12">CADILLAC ESCALADE ESV</h>
                <img className='bg-none px-6 bg-transparent object-cover object-center md:w-[600px] w-full' src={cad} />
            </div>
        </div>

    </>
  )
}

export default Fleet